// media queries

@media (min-width: @grid-float-breakpoint){
    .homeblock { border-right: 1px solid #ccc };
 }
@media (max-width: @grid-float-breakpoint){
    .control-label { text-align: left !important };
 }
@media (max-width: @screen-sm-max){
    #bannerContainer {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media (min-width: 1200px) {
    .container{
        max-width: 910px;
    }
}

 .navbar-default { .navbar-inverse() };

// tags

body {
	padding-top: 75px;
	max-width: @app-max-width;
	.center-block();
	padding-left: 15px;
	padding-right: 15px !important;
    height: 100%;
    &.higher {
        padding-top: 50px;
    }
}

footer {
	padding-top: 25px;
	padding-bottom: 25px;
	font-size: @font-size-small;
	background-color: darken(@navbar-inverse-bg, 10%);
	color: darken(@navbar-inverse-color, 10%);
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;

    a {
    	color: @navbar-inverse-color;
    	&:focus,
    	&:hover,
    	&:active {
    		color: @navbar-inverse-link-hover-color;
    	}
    }
    .btn-default {
    	color: darken(@navbar-inverse-bg, 10%) !important;
    }
    h3 {
    	font-size: 120%;
    	margin-bottom: 4px;
    	text-transform: uppercase;
    }
    ul {
    	list-style: none;
    	padding: 0;
    	margin: 0;
    }
}

h3.home {
    color: @navbar-inverse-bg;
}

h1.home {
    color: @navbar-inverse-bg;
    text-align: center;
    margin-top: 0px;
}

canvas {
    background: #fff;
}

input[type='checkbox'] {
    margin-top: 8px;
}


// IDs

#testsOverview {
    cursor: pointer;
}

#modalSummaryBody {
    overflow-y: scroll;
}

#quote-carousel {
    padding: 0 10px 30px 10px;
    text-align:center;
}

#quote-carousel .carousel-indicators {
    right: 50%;
    top: auto;
    bottom: -10px;
    margin-right: -19px;
}

#quote-carousel .carousel-indicators li {
    background: #c0c0c0;
}

#quote-carousel .carousel-indicators .active {
    background: #333333;
    height: 10px;
    width: 10px;
    margin-bottom: 1px;
}

#attachmentsDropzone {
    border: 2px dashed @brand-primary;
    border-radius: 5px;
    text-alignment: center;
    height: 200px;
}


// classes
 .swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
} 

.canvas-loader {
    position: absolute;
}

.homeblueblock {
    background-color: darken(@navbar-inverse-bg, 10%);
    color: darken(@navbar-inverse-color, 10%);
}

.collapse-well, .collapse-h4 {
    cursor: pointer;
}

.color-danger {
    color: @brand-danger;
}

.color-warning {
    color: @brand-warning;
}

.icon-navbar {
	left: 12px;
    position: absolute;
    top: 8px;
    color: white;
}

.label-banner {
	display: block;
	text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.top-buffer {
	margin-top: 20px;
}

.buffer-large {
	margin-top: 50px;
}

.section-split {
  border:none;
  border-top: 2px dotted @gray;
  width: 100%;
}

.img-center {
	margin: 0 auto;
}

.footer-wrapper {
	max-width: @app-max-width;
	.center-block();
}

.canvas-scale {
    width: 100%;
}

.panel-heading .accordion-closed:after {
    font-family: "FontAwesome";
    content: "\f054";
    float: right;
    color: grey;
}

.panel-heading .accordion-open:after {
    font-family: "FontAwesome";
    content: "\f078";
    float: right;
    color: grey;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
    font-size: 12px;
    line-height: 14px;
}

.selectize-input {
    min-height: 20px;
}

.form-control.selectize-control {
    height: 30px;
}

.fa-sm {
    font-size: 1em;
}

.prevnextbtns {
    margin: 10px;
    width: 120px;
}

.panel-canvas {
    background-color: #fff;
}

.clean {
    text-decoration: none !important;
}

.white-bg {
    background-color: #fff !important;
}

.has-error{
    border-color: red;
}

.grey-text{
    color: #bbb;
}

.btn-left {
    border-right-width: 3px;
}

.btn-right {
    border-left-width: 3px;
}

.input-group-addon {
    background-color: white;
}

.navbar .divider-vertical {
    height: 50px;
    margin: 0 9px;
    border-right: 1px solid #ffffff;
    border-left: 0px;
}

.modal-open {
  overflow-y: auto;
}

.dropdown-menu {
  > li > a {
    padding: 7px 20px;
  }
}

input:-moz-read-only { /* For Firefox */
    background-color: @gray-lightest !important;
}

input:read-only {
    background-color: @gray-lightest !important;
} 

.deleteAttachmentButton {
    cursor: pointer;
    color: @gray-light;
}